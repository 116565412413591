.page-footer{
  background-color: var(--primary);
  min-height: 50vh;
  padding: 2rem;
  padding-bottom: 5rem;
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr;
  justify-content: space-between;
  &__card{
    padding: 1em;
    background-color: var(--primary-light);
    box-shadow: 0px 0px 2rem 1.2rem var(--primary-light);
    &__title{
      color: var(--white);
    }
    &__list{
      list-style: none;
      padding: 0;
    }
  }
  .logo{
    max-width: 100%;
    height:auto;
  }
}
@media (min-width: 720px) {
  .page-footer{
    grid-template-columns: repeat(3, minmax(20ch, 50ch));
    .page-footer__card{
      &--logo{
        grid-column: 2/3;
        grid-row: 1;
      }
    }
  }
}
html{
  font-size: 16px;
  // scroll-behavior: smooth;
}

:focus{
  // box-shadow: 0px 0px 0px 3px var(--secondary), 0 0 0 7px hotpink;
  // outline: none;
  outline-offset: 3px;
  outline-width: 3px;
  outline-style: groove;
  outline-color: invert;
}
Link, a{
  color: var(--link);
  text-decoration: none;
  &:hover{
    text-decoration: underline;
  }
}
.page{
  padding-top: 4rem;
}
.page-container{
  min-height: 100vh;
  display: grid;
  grid-template-rows: 1fr auto;
}
.portfolio-picture{
  display: inline-block;
  img{
    max-width: 100vw;
    width: 100%;
    height: auto;
  }
  figcaption{
    font-family: 'Libre Barcode 128 Text', monospace;
    font-size: 2.6rem;
  }
}
:root{
  --primary: #5E775A;
  --primary-light: hsl(112, 14%, 43%);
  --secondary: #df7e29;
  --white: #ededed;
  --black: #222222;
  --link: #0E215C;

  --spacing: 1rem;
  --box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.6);
}
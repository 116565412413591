.image-section{
  min-height: 105vh;
  
  display: grid;
  grid-template-columns: 2.2em auto;
  background-color: var(--white);
  // padding: var(--spacing);
  .tag{
    font-size: 1rem;
    position: sticky;
    top: 30%;
    writing-mode: vertical-lr;
    background: var(--secondary);
    padding: .7em 0.3em;
    border-radius: 0 1em 1em 0;
    box-shadow: var(--box-shadow);
    color: var(--white);
    height: fit-content;
    h2{
      font-size: inherit;
      display: inline;
    }
    &-section-up{
      border: none;
      padding: 0;
      display: inline-block;
    }
    .arrow{
      font-size: inherit;
      border-left: .7em solid transparent;
      border-right: .7em solid transparent;
      border-bottom: .7em solid var(--white);
      margin-top: .3em;
      margin-bottom: .3em;
      display: inline-block;
    }
  }
  .image-set{
    display: grid;
    grid-auto-rows: auto;
    grid-template-rows: masonry;
    padding: calc(var(--spacing) * 4) 1rem;
    
    // margin-top: -7rem;
    padding-left: .1em;

    &-image-button{
      cursor: pointer;
      
      margin: 3rem 0;
      @supports (grid-template-rows: masonry) {
        margin: 3px 0;
      }
      text-align: left;
      border: 0;
      
      .portfolio-picture {
        img{
  
          box-shadow: 0 0 0 rgba(0, 0, 0, 0.7);
          transition: box-shadow 250ms;
        }
        &:hover img{
          box-shadow: 0 2px 5px 1px rgba(0, 0, 0, 0.7);
        }
      }
    }
  }
  &:nth-child(2n+1){
    background: var(--primary);
  }
}
@media (min-width: 720px) {
  .image-section{
    grid-template-columns: 3.2em auto;
    .image-set{
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      align-items: center;
      gap: var(--spacing);
    }
    .tag{
      font-size: 1.5rem;
      transition: box-shadow 50ms ease-in-out;
      &:hover{
        box-shadow: 0 0 7px 4px rgba(0, 0, 0, 0.6);;
      }
    }
  }
}
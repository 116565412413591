.blog{
  padding: .5rem;
  background: var(--primary-light);
}
.blog-post{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr auto 2rem;
  padding: 1rem;
  border-bottom: 1px solid black;
  background: var(--white);

  @media (min-width: 720px) {
    grid-template-columns: minmax(120px, 420px) 1fr;
    grid-template-rows: auto 1fr 2rem;
  }
  
  &__header{
    grid-column: 1;
    text-align: center;
    font-size: 2.4em;
    
    @media (min-width: 720px) {
      text-align: left;
      grid-column: 1 / end;
    }
    h3{
      position: relative;
    }
    h3::after {
      content:'';
      position: absolute;
      width: calc(80% + 2rem);
      bottom: -0.2em;
      left:-2rem;
      height:10px;
      background: var(--secondary);
      border-radius: 0 0 20% / 100% 0;
    }
  }
  &__feture-image{
    img{
      max-width: 100%;
      height: auto;
    }

  }
  &__content{
    height: 100%;
    @media (min-width: 720px) {
      columns: 45ch;
      margin-left: 1rem;
      column-fill: auto;
    }

  }
  &__footer{
    grid-row: 4;
    align-self: end;
    border-top: 1px solid var(--black);
    @media (min-width: 720px) {
      grid-row: 3;
      grid-column: 1 / end;
    }
    font-size: smaller;
  }

}
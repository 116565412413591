.section-link-list {
  align-self: center;
  margin-left: var(--spacing);
  padding: 0;
  list-style: none;
  &-item {
    font-size: 2rem;
    margin-top: var(--spacing);
    &-link {
      border:none;
      color: var(--secondary);
      text-decoration: none;
      text-shadow: 0px 0px 2px rgba(0, 0, 0, 0.75);
      display: inline-block;
      transition: all cubic-bezier(0.68, -0.55, 0.27, 1.55) 500ms;
      
      &:hover {
        text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.7);
        transform: translateY(-4px);
        
      }
    }
  }
}
.picture-nav{
  // height: 100vh;
  // min-height: 1200px;
  display: grid;
  grid-template-rows: 100vh 100vh;
  grid-template-columns: auto;
  .fold-image{
    width: auto;
    min-height: 200vh;
    display: block;
    overflow: hidden;
    position: relative;
    z-index: -1;
    grid-column: 1/2;
    grid-row: 1/2;
    img{
      position: absolute;
      left: 50%;
      transform: translateX(-50%) translateY(30%);
      bottom: 30%;
      width: auto;
      height: 100%;
    }
  }
  .section-link-list{

    grid-column: 1;
    grid-row: 1/2;
  }
  .presentation{
    grid-column: 1;
    grid-row: 2/3;
    justify-content: center;
    align-content: center;
    margin: auto;
    color: var(--black);
    max-width: 80ch;
    padding: 1em;
    background-color: rgba(237, 237, 237, .5);
  }
  
}

@media (min-width: 720px) {
  .picture-nav{
    grid-template-rows: 100vh;
    grid-template-columns: 1fr auto 1fr;
    .fold-image{
      grid-column: 1/4;
      grid-row: 1;
      min-height: auto;
      height: 100vh;
      img{
        width: 101%;
        height: auto;
      }
    }
    .section-link-list{
      grid-column: 1/2;
      grid-row: 1;
      min-width: 140px;
    }
    .presentation{
      grid-column: 2/3;
      grid-row: 1;
    }
  }
}
.contact{
  margin: 1rem auto;
  max-width: 500px;

// contact__name
// contact__email
// contact__subject
// contact__message
// contact__captcha
// contact__submit
  .email-sent{
    display: none;
    &.sent {
      display: block
    }
  }

  &-form{
    &.sent{
      display: none
    }
    label {
      display: block;
      &+label{
        margin-top: 1rem;
      }
    }
    input[type=email],
    input[type=number],
    input[type=password],
    input[type=text],
    textarea {
      display: block;
      padding: 0.5em 1em;
      width: 100%;
      max-width: 300px;
      border-width: 2px;
      border-radius: 1.5em;
      
      &:focus {
        outline-offset: 0;
        outline: 0;
        border-color: var(--primary);
      }
    }
    
    &__subject{
      input{
        max-width: 100% !important;
      }
    }
    &__message{
      textarea {
        min-height: 2.8em;
        resize: vertical; 
        max-width: 100%;
      }
    }
    &__captcha{
      margin: 1rem 0;
      cursor: pointer;
    }
    &__submit{
      background-color: var(--secondary);
      padding: 0.7em 1.2em;
      border-radius: 1.5em;
      font-weight: 800;
      color: var(--white);
      cursor: pointer;
      &:focus {
        outline-offset: 0;
        outline: 0;
        border-color: var(--primary);
      }
    }
  }
}